<template>
    <div id="voucher" class="">
        <table class="w-100 container">
          <tbody>
            <tr><td class="container" v-if="recordStore" id="voucher">
                <div class="row">
                    <div class="col-12">
                        <img id="logo" src="@/extra/custom/img/logo-full.png" class="py-0">
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-12">
                        <span>Passengers:</span>
                    </div>
                    <div class="col-12">
                        <span>{{passengersNames}}</span>
                    </div>
                </div>
                <div v-for="(supplierData, sId) of getSuppliersAndHotels">
                    <voucher
                        class="not-break-inside"
                        :supplierData="supplierData"
                        :sId="sId"
                        :edit="edit"
                    ></voucher>
                    <p v-if="getPageBreak(sId+1)" class="page-break" style="page-break-after: always;">&nbsp;</p>
                    <p v-if="getPageBreak(sId+1)" class="page-break" style="page-break-before: always;">&nbsp;</p>
                </div>
                <div v-if="flightsList.length">
                    <div class="row table-voucher">
                        <div class="col-12 py-2">
                            <div class="row d-flex justify-content-center">
                                <span>Flights</span>
                            </div>
                            <div v-for="f of flightsList">
                                <div class="row">
                                    <span class="col-12">{{f}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-12">
                        <span>Emergency Numbers:</span>
                    </div>
                    <div class="col-12">
                        <text-rows-style :text="recordStore.EmergencyContacts"></text-rows-style>
                    </div>
                </div>
                <div class="row mt-2 footer-text not-print">
                    <div class="col-12">
                        <span>P.T. RAJA PARIWISATA INDONESIA</span>
                    </div>
                    <div class="col-12">
                        <span>JALAN KRAGILAN-POTRONANGGAN.</span>
                    </div>
                    <div class="col-12">
                        <span>TAMANAN. BANGUNTAPAN. BANTUL.</span>
                    </div>
                    <div class="col-12">
                        <span>DAERAH ISTIMEWA YOGYAKARTA</span>
                    </div>
                    <div class="col-12">
                        <span>55191, INDONESIA</span>
                    </div>
                    <div class="col-12">
                        <span>PHONE: +62 274 - 2871827</span>
                    </div>
                    <div class="col-12">
                        <span>info@rajatoursindonesia.com</span>
                    </div>
                    <div class="col-12">
                        <span>www.rajatoursindonesia.com</span>
                    </div>
                </div>
            </td></tr>
          </tbody>
          <tfoot>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </tfoot>

        </table>
        <div class="row ml-2 footer-text divFooter report-footer">
            <div class="col-12">
                <span>P.T. RAJA PARIWISATA INDONESIA</span>
            </div>
            <div class="col-12">
                <span>JALAN KRAGILAN-POTRONANGGAN.</span>
            </div>
            <div class="col-12">
                <span>TAMANAN. BANGUNTAPAN. BANTUL.</span>
            </div>
            <div class="col-12">
                <span>DAERAH ISTIMEWA YOGYAKARTA.</span>
            </div>
            <div class="col-12">
                <span>55191, INDONESIA</span>
            </div>
            <div class="col-12">
                <span>PHONE: +62 274 - 2871827</span>
            </div>
            <div class="col-12">
                <span>info@rajatoursindonesia.com</span>
            </div>
            <div class="col-12">
                <span>www.rajatoursindonesia.com</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import '@/extra/custom/css/voucher.scss';
const voucher = importVueComp('components/voucher', 'Voucher');
export default {
    name: 'custom-vouchers-preview',
    props: ['edit'],
    components: {
        'voucher': voucher,
    },
    data () {
        return {
            image: 'img',
            editing: null,
            passengerList: [],
        }
    },
    async mounted () {
        this.passengerList = await this.getPassengers();
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
            processing: state => state.main.processing,
        }),
        getSuppliersAndHotels () {
            let res = {}
            let k = 0;
            if (!this.recordStore) return res;
            if (this.recordStore && this.recordStore.BookingDays) {
                for (let day of this.recordStore.BookingDays) {
                    let key = 'd-' + day.DayNr;
                    let entrance;
                    for (let service of day.BookingDayServices) {
                        k += 1;
                        if (service.EntranceIncluded) entrance = true;
                        if (entrance && res[key]) res[key].entrance = true;
                        if (service.VoucherDisabled) continue;
                        if (service.Service && service.Service.VoucherDisabled) continue;
                        if (service.Optional) continue;
                        if (service.Status == 'CANCELED') continue;
                        if (!service.Supplier) continue;
                        if (!service.Supplier.id) continue;
                        //if (service.Service && service.Service.NotPrice) continue;
                        let comments = '';
                        if (key in this.recordStore.VoucherComments) {
                            comments = this.recordStore.VoucherComments[key];
                        }
                        if (!res[key]) {
                            res[key] = {
                                //Supplier: service.Supplier,
                                Services: [],
                                Comments: comments,
                                key,
                                entrance,
                                DayNr: day.DayNr,
                            };
                            res[key].order = k;
                            res[key].ServiceDate = this.getDate(service.ServiceDate, 'DD/MM')
                            res[key].Comments = '';
                        }
                        res[key].Services.push(service);
                    }
                    let rowNr = 0;
                    for (let voucher of day.BookingDayVouchers) {
                        k += 1;
                        if (!res[key]) {
                            res[key] = {
                                Services: [],
                                key,
                                entrance: null,
                                DayNr: day.DayNr,
                            };
                            res[key].order = k;
                            res[key].ServiceDate = this.getDate(day.TransDate, 'DD/MM')
                            res[key].Comments = '';
                        }
                        voucher.extra = true;
                        voucher.rowNr = rowNr;
                        voucher.DayNr = day.DayNr;
                        res[key].Services.push(voucher);
                        rowNr += 1;
                    }
                    if (day.BookingDayHotels[0]) {
                        for (let room of day.BookingDayHotels[0].BookingDayRooms) {
                            if (!room.Hotel) continue;
                            if (!room.roomQuoteSelected) continue;
                            if (room.Status == 'CANCELED') continue;
                            let key = [day.DayNr, room.Hotel.id];
                            if (!res[key]) {
                                k += 1;
                                res[key] = {
                                    Supplier: room.Hotel,
                                    Rooms: [],
                                    TransDate: room.TransDate,
                                    days: room.nights,
                                    DayNr: day.DayNr,
                                };
                                res[key].order = 1;
                                res[key].ServiceDate = day.TransDate;
                                res[key].DateIn = moment(day.TransDate).format('DD/MM');
                                res[key].DateOut = moment(day.TransDate).add(day.BookingDayHotels[0].Days, 'days').format('DD/MM');
                            }
                            if (room.Room.ExtraBed) {
                                /*let _room = (_.find(res[key].Rooms, (rr) => {
                                    if (rr.CategoryId == room.CategoryId && !rr.Room.ExtraBed) {
                                        return true;
                                    }
                                }));
                                if (_room) {
                                    _room._extraBed = room.Rooms;
                                } else {
                                    res[key].Rooms.push(room);
                                }*/
                            } else {
                                res[key].Rooms.push(room);
                            }
                        }
                    }
                }
            }
            return res;
        },
        passengersNames () {
            if (this.passengerList.length>0) {
                return this.passengerList.join(' / ');
            }
            return this.recordStore.PaxName;
        },
        flightsList () {
            let res = [];
            if (!this.recordStore.BookingFlights) return res;
            for (let f of this.recordStore.BookingFlights) {
                if (f.Output) {
                    let rows = f.Output.split('\n');
                    for (let r of rows){
                        res.push(r);
                    }
                }
            }
            return res;
        },

    },
    methods: {
        getDate (dateIn, format) {
            return moment(dateIn).format(format);
        },
        getPageBreak (i) {
            if (i==Object.keys(this.getSuppliersAndHotels).length) return false;
            if (i % 3 == 0) return true;
            return false;
        },
        evenListener (e) {
            if (e.keyCode == 13) {
                this.editing = null;
            }
            if (e.keyCode == 9) {
                this.editing = null;
            }
            if (e.keyCode == 27) {
                this.editing = null;
            }
        },
        async getPassengers () {
            if (!this.recordStore) return [];
            let paxList = [];
            let rf = await api.get('/api/bookingform/', {filters: JSON.stringify({BookingId: this.recordStore.id })})
            if (rf && rf.length==1) {
                for (let pax of rf[0].BookingFormPaxs) {
                    let paxName = tools.toTitleCase(frontTools.value(pax.FirstName)) + ' ' +
                        tools.toTitleCase(frontTools.value(pax.LastName));
                    paxList.push(paxName);
                }
            }
            return paxList;
        },
        toTitleCase (v) {
            return tools.toTitleCase(v);
        }

    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.evenListener);
    },
    created () {
        setTimeout(()=> document.addEventListener("keydown", this.evenListener), 1000);
    },
}
</script>
