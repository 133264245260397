<script>
import Vue from 'vue';
import { mapState } from 'vuex';

let bookingCoverMainExtras = Vue.component('booking-cover-main-extras', {
    props: [],
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
            salesActions: state => state.main.salesActions,
            managerActions: state => state.main.managerActions,
        }),
        canEdit () {
            if (!this.recordStore.isTemplate) return this.salesActions || this.managerActions;
            return true;
        }
    },
    template: `
        <div class="col-md-12 pl-3">
            <div class="row">
                <!-- Product Line select input -->
                <div class="col-md-2" v-if="recordStore.StartDate && recordStore.StartDate > '2024-31-12'">
                    <select-input
                        fieldName="IslandType"
                        :field="recordStore.fieldsObject.IslandType"
                        label="Product Line"
                        :record="recordStore"
                        :addClass="recordStore.invalid.IslandType ? 'border-danger' : ''"
                        :addBlank="true"
                        :currentValue.sync="recordStore.IslandType"
                    ></select-input>
                </div>

                <!-- Closed checkbox -->
                <div class="col-md-2 pt-4" v-if="recordStore.Status == 'CATALOGUE'">
                    <div class="form-check">
                        <input
                            :disabled="!canEdit"
                            v-model="recordStore.Closed" 
                            name="Closed" 
                            class="form-check-input"
                            type="checkbox" 
                            id="Closed"
                        >
                        <label class="smaller form-check-label ml-1" for="Closed">{{tr('Archive')}}</label>
                    </div>
                </div>
            </div>
        </div>
    `,
})

const bookingCoverMain = importVueComp('components/booking', 'BookingCoverMain', 'custom');
export default {
    name: 'custom-booking-cover-main',
    mixins: [bookingCoverMain],
    mounted () {
        //window._booking = this.recordStore
        this.extraComp.push({
            component: bookingCoverMainExtras,
            ref: 'bookingCoverMainExtras',
            change: () => {},
        })
    },
}
</script>

<style scoped>
.l-date{
    top: -42px;
    position: relative;
}
</style>

