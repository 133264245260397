<template>
    <div class="col-md-3 mt-2 d-flex flex-column pl-3">
        <booking-quote-config-checkbox></booking-quote-config-checkbox>
        <div class="form-check checkbox-group">
            <input v-model="record.HideRoomType" class="form-check-input" type="checkbox"
                @change="change('HideRoomType')"
                id="HideRoomType">
            <label for="HideRoomType" class="smaller form-check-label">{{tr('Hide Room Type')}}</label>
        </div>
        <div class="form-check checkbox-group">
            <input v-model="record.DisableAutoExpenses" class="form-check-input" type="checkbox"
                @change="change('DisableAutoExpenses')" :disabled="!canDisableAutoExpenses"
                id="DisableAutoExpenses">
            <label for="DisableAutoExpenses" class="smaller form-check-label">{{tr('Disable Fixed Costs')}}</label>
        </div>
    </div>
</template>

<script>
const bookingQuoteConfigCheckbox = importVueComp('components/booking', 'BookingQuoteConfigCheckbox', 'custom');
export default {
    name: 'custom-booking-quote-config-checkbox',
    mixins: [bookingQuoteConfigCheckbox],
    components: {
        'booking-quote-config-checkbox': bookingQuoteConfigCheckbox,
    },
    computed: {
        recordStore () {
            return this.$store.state.main.record;
        },
        canDisableAutoExpenses () {
            return tools.canAccess(this.user, 'api', 'POST', 'disable_auto_expenses', false);
        },
    },
}
</script>
